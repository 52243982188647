import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const paymentAPI = createApi({
  reducerPath: "paymentAPI",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["refetchPayment"],
  endpoints: (builder) => ({
    getPaymentMethod: builder.query({
      query: () => ({
        url: `/paymentMethods`,
        method: "GET",
      }),
      providesTags: ["refetchPayment"],
    }),

    addPaymentMethod: builder.mutation({
      query: (payload) => ({
        url: `/paymentMethods`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["refetchPayment"],
    }),
  }),
});

export const { useGetPaymentMethodQuery, useAddPaymentMethodMutation } =
  paymentAPI;
