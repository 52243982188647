import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { asyncGetDriverDetails } from "../../redux/slices/driverSlice";
import PageLoader from "../../components/loading/PageLoader";
import RatingComponent from "../../components/RatingComponent";
import CustomButton from "../../components/CustomButton";
import { BUCKET_PUBLIC_URL } from "../../constants";

const personalDetails = {
  profileImageUrl: "https://img.freepik.com/free-icon/user_318-563642.jpg",
  gender: "Male",
  verification: "Verified",
  personalDetailsSubmitted: true,
  vehicleDetailsSubmitted: true,
  documentsSubmitted: true,
  registerationCompleted: true,
  status: "Active",
  phone: "+923362481487",
  createdAt: "2023-10-27T11:40:02.538Z",
  updatedAt: "2023-10-27T17:42:48.177Z",
  drivingLicenseExpiry: "123445",
  drivingLicenseNumber: "123445",
  email: "muzamil@gmail.com",
  firstName: "Muzamil",
  identityCardExpiry: "2023-10-24",
  identityCardNumber: "12345",
  lastName: "Ahmed",
  id: "653ba19209fd3a1a2cccd262",
};

const documents = [
  {
    status: "Pending",
    modelNameOfTheDocumentFor: "Driver",
    documentType: {
      hasExpiry: true,
      hasBackSide: true,
      name: "Driving License",
      id: "652fbe1a8c289e4d5c9498e3",
    },
    documentFor: {
      profileImageUrl: "https://img.freepik.com/free-icon/user_318-563642.jpg",
      gender: "Male",
      verification: "Verified",
      personalDetailsSubmitted: true,
      vehicleDetailsSubmitted: true,
      documentsSubmitted: true,
      registerationCompleted: true,
      status: "Active",
      phone: "+923362481487",
      createdAt: "2023-10-27T11:40:02.538Z",
      updatedAt: "2023-10-27T17:42:48.177Z",
      drivingLicenseExpiry: "123445",
      drivingLicenseNumber: "123445",
      email: "muzamil@gmail.com",
      firstName: "Muzamil",
      identityCardExpiry: "2023-10-24",
      identityCardNumber: "12345",
      lastName: "Ahmed",
      id: "653ba19209fd3a1a2cccd262",
    },
    frontSideUrl:
      "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/driver-uploads/653ba19209fd3a1a2cccd262/652fbe1a8c289e4d5c9498e3/front/1698406954466/btsport2.png",
    backSideUrl:
      "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/driver-uploads/653ba19209fd3a1a2cccd262/652fbe1a8c289e4d5c9498e3/back/1698406958459/btsport4.png",
    expirationDate: "2023-10-28T00:00:00.000Z",
    createdAt: "2023-10-27T11:43:09.851Z",
    updatedAt: "2023-10-27T12:20:49.919Z",
    driver: "653ba19209fd3a1a2cccd262",
    id: "653ba24d09fd3a1a2cccd283",
  },
  {
    status: "Pending",
    modelNameOfTheDocumentFor: "Vehicle",
    documentType: {
      hasExpiry: true,
      hasBackSide: false,
      name: "Insurance",
      id: "652fbe2d8c289e4d5c9498e7",
    },
    documentFor: {
      vehicleImages: [],
      verification: "Pending",
      status: "Active",
      make: "Toyota",
      model: "Passo",
      year: "2012",
      color: "White",
      plateNumber: "KKU-645",
      engineCC: 999,
      category: "65144e5bbf5066297c006c4d",
      driver: "653ba19209fd3a1a2cccd262",
      createdAt: "2023-10-27T11:42:15.794Z",
      updatedAt: "2023-10-27T11:42:15.794Z",
      id: "653ba21709fd3a1a2cccd27c",
    },
    frontSideUrl:
      "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/driver-uploads/653ba19209fd3a1a2cccd262/652fbe2d8c289e4d5c9498e7/front/1698406967106/btsport4.png",
    backSideUrl: "",
    expirationDate: "2023-10-28T00:00:00.000Z",
    createdAt: "2023-10-27T11:43:09.851Z",
    updatedAt: "2023-10-27T14:25:49.269Z",
    driver: "653ba19209fd3a1a2cccd262",
    id: "653ba24d09fd3a1a2cccd284",
  },
  {
    status: "Pending",
    modelNameOfTheDocumentFor: "Vehicle",
    documentType: {
      hasExpiry: false,
      hasBackSide: false,
      name: "Vehicle Registeration",
      id: "652fbe728c289e4d5c9498eb",
    },
    documentFor: {
      vehicleImages: [],
      verification: "Pending",
      status: "Active",
      make: "Toyota",
      model: "Passo",
      year: "2012",
      color: "White",
      plateNumber: "KKU-645",
      engineCC: 999,
      category: "65144e5bbf5066297c006c4d",
      driver: "653ba19209fd3a1a2cccd262",
      createdAt: "2023-10-27T11:42:15.794Z",
      updatedAt: "2023-10-27T11:42:15.794Z",
      id: "653ba21709fd3a1a2cccd27c",
    },
    frontSideUrl:
      "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/driver-uploads/653ba19209fd3a1a2cccd262/652fbe728c289e4d5c9498eb/front/1698406972383/btsport3.png",
    backSideUrl: "",
    createdAt: "2023-10-27T11:43:09.851Z",
    updatedAt: "2023-10-27T14:27:11.512Z",
    driver: "653ba19209fd3a1a2cccd262",
    expirationDate: null,
    id: "653ba24d09fd3a1a2cccd285",
  },
  {
    status: "Pending",
    modelNameOfTheDocumentFor: "Vehicle",
    documentType: {
      hasExpiry: true,
      hasBackSide: false,
      name: "Vehicle Inspection",
      id: "652fbe898c289e4d5c9498ef",
    },
    documentFor: {
      vehicleImages: [],
      verification: "Pending",
      status: "Active",
      make: "Toyota",
      model: "Passo",
      year: "2012",
      color: "White",
      plateNumber: "KKU-645",
      engineCC: 999,
      category: "65144e5bbf5066297c006c4d",
      driver: "653ba19209fd3a1a2cccd262",
      createdAt: "2023-10-27T11:42:15.794Z",
      updatedAt: "2023-10-27T11:42:15.794Z",
      id: "653ba21709fd3a1a2cccd27c",
    },
    frontSideUrl:
      "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/driver-uploads/653ba19209fd3a1a2cccd262/652fbe898c289e4d5c9498ef/front/1698406983045/btsport4.png",
    backSideUrl: "",
    expirationDate: "2023-11-01T00:00:00.000Z",
    createdAt: "2023-10-27T11:43:09.851Z",
    updatedAt: "2023-10-27T14:30:22.020Z",
    driver: "653ba19209fd3a1a2cccd262",
    id: "653ba24d09fd3a1a2cccd286",
  },
];

const isLoading = false;

function EditSubmittedDocuments() {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const changeHandler = (index, e) => {
    // setDocumentsData((prev) =>
    //   prev.map((x, i) =>
    //     index == i ? { ...x, [e.target.name]: e.target.value } : x
    //   )
    // );
  };

  const downloadImage = (imageUrl, fileName) => {
    // Create a hidden anchor element
    const link = document.createElement("a");
    link.style.display = "none";

    // Set the URL and the download attribute
    link.href = imageUrl;
    link.setAttribute("download", fileName);

    // Trigger a click event to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up the anchor element
    document.body.removeChild(link);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-block card-stretch">
            <div className="card-body p-0">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h5 className="font-weight-bold">
                  View Driver Submitted Documents
                </h5>
                <Link
                  to="/documents/submitted"
                  className="float-right btn btn-sm btn-primary"
                >
                  <i className="fa fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-block p-card">
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={`${BUCKET_PUBLIC_URL}/${personalDetails?.profileImageUrl}`}
                    alt="01.jpg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {personalDetails?.firstName} {personalDetails?.lastName}
                  </h3>
                  <p className="text-white text-center mb-5">
                    <span className="text-capitalize badge bg-success ">
                      {personalDetails?.status}
                    </span>
                  </p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-envelope" />
                    </div>
                    <p className="mb-0 eml">{personalDetails?.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <p className="mb-0">{personalDetails?.phone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i
                        className={`fas fa-${personalDetails?.gender?.toLowerCase()}`}
                      />
                    </div>
                    <p className="mb-0">{personalDetails?.gender}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    {personalDetails?.verification === "Verified" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-check`} />
                        </div>
                        <p className="mb-0">{personalDetails?.verification}</p>
                      </>
                    ) : personalDetails?.verification === "Pending" ? (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fa fa-clock`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="p-icon mr-3">
                          <i className={`fas fa-ban`} />
                        </div>
                        <p className="mb-0">
                          Verification {personalDetails?.verification}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              {documents.map((x) => (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">{x?.documentType?.name}</h4>
                      </div>
                    </div>
                    <div className="card-body pb-5">
                      <div className="new-user-info">
                        <div className="row">
                          {/* <div className="form-group col-md-4">
                            <label
                              htmlFor="driver_id"
                              className="form-control-label"
                            >
                              Select Driver{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <CustomSelectAsync
                              loadOptions={loadDriverOptions}
                              name="documentFor"
                              placeholder="Search here"
                              onChange={changeHandler}
                              formData={formData}
                              value={formData.documentFor}
                              initialValue={documentForInitialValue}
                            />
                          </div> */}
                          {/* <div className="form-group col-md-4">
                            <label
                              htmlFor="document_id"
                              className="form-control-label"
                            >
                              Select Document{" "}
                              <span className="text-danger">* </span>
                            </label>
                            <CustomSelect
                              name="documentType"
                              placeholder="Select document"
                              options={requiredDocumentsList.map((x) => ({
                                label: x.name,
                                value: x._id,
                              }))}
                              onChange={changeHandler}
                              initialValue={documentTypeInitialValue}
                            />
                          </div> */}
                          {x.documentType?.hasExpiry && (
                            <div className="form-group col-md-4">
                              <label
                                className="form-control-label"
                                htmlFor="expire_date"
                              >
                                Expiry Date{" "}
                                <span
                                  className="text-danger"
                                  id="has_expiry_date"
                                />{" "}
                              </label>
                              <input
                                className="form-control min-datepicker flatpickr-input"
                                placeholder="Expiry Date"
                                type="date"
                                // onChange={changeHandler}
                                name="expirationDate"
                                value={moment(x.expirationDate).format(
                                  "YYYY-MM-DD"
                                )}
                                // value={"04-11-2022"}
                                // defaultValue="2023-10-12"
                                // disabled
                              />
                            </div>
                          )}
                          <div className="form-group col-md-4">
                            <label
                              htmlFor="is_verified"
                              className="form-control-label"
                            >
                              Verification{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="status"
                              onChange={changeHandler}
                              value={x.status}
                            >
                              <option value="Peding">Pending</option>
                              <option value="Verified">Verified</option>
                              <option value="Rejected">Rejected</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="form-group col-md-4">
                            <label
                              className="form-control-label"
                              htmlFor="driver_document"
                            >
                              Uploaded documents
                              <span
                                className="text-danger"
                                id="document_required"
                              />{" "}
                            </label>
                            <div>
                              {x.frontSideUrl && x.frontSideUrl ? (
                                <img src={x.frontSideUrl} height={150} />
                              ) : (
                                <></>
                              )}
                              {x.documentType?.hasBackSide &&
                              x.backSideUrl &&
                              x.backSideUrl ? (
                                <img src={x.backSideUrl} height={150} />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div> */}
                          <div className="d-flex gap-2">
                            <div className="m-2">
                              <h5>Front side:</h5>
                              <img
                                src={x.frontSideUrl}
                                alt="Image"
                                className="img-fluid"
                                width="300px"
                              />
                              <div>
                                <Link
                                  onClick={() =>
                                    downloadImage(x.frontSideUrl, "document")
                                  }
                                  to=""
                                >
                                  Download
                                </Link>
                              </div>
                            </div>
                            {x.backSideUrl && x.backSideUrl !== "" && (
                              <div className="m-2">
                                <h5>Back side:</h5>
                                <img
                                  src={x.backSideUrl}
                                  alt="Image"
                                  className="img-fluid"
                                  width="300px"
                                />
                                <div>
                                  <Link
                                    onClick={() =>
                                      downloadImage(x.backSideUrl, "document")
                                    }
                                    to=""
                                  >
                                    Download
                                  </Link>
                                </div>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div>
                        <CustomButton
                          className="btn btn-md btn-primary float-right"
                          // onClick={postDriverDocumentsHandler}
                          title="Submit"
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditSubmittedDocuments;
