import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import DefaultProfileImg from "../../assets/images/default-profile-img.png";
import CheckPermission from "../../utils/checkPermission";
import { BUCKET_PUBLIC_URL } from "../../constants";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";
const DriversPendingTable = () => {
  const location = useLocation();
  const columns = [
    {
      key: "Name",
      displayName: "Name",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <img
            width={40}
            height={40}
            src={`${BUCKET_PUBLIC_URL}/${e.profileImageUrl}`}
            className="rounded-circle object-fit-cover"
            onError={(e) => {
              e.target.src = DefaultProfileImg;
            }}
          />
          <p className="ml-2 mb-0">
            {e.firstName} {e.lastName}
          </p>
        </div>
      ),
      searchable: true,
    },
    {
      key: "email",
      displayName: "Email",
      searchable: true,
    },
    {
      key: "phone",
      displayName: "Phone",
      searchable: true,
    },
    {
      // key: "status",
      displayField: (e) => (
        <>
          {e.verification === "Pending" ? (
            <span className="text-capitalize badge bg-primary">
              {e.verification}
            </span>
          ) : e.verification === "Verified" ? (
            <span className="text-capitalize badge bg-success">
              {e.verification}
            </span>
          ) : e.verification === "Rejected" ? (
            <span className="text-capitalize badge bg-danger">
              {e.verification}
            </span>
          ) : (
            <></>
          )}
        </>
      ),
      displayName: "Verification",
    },

    {
      // key: "status",
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Active" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <div className="d-flex justify-content-start align-items-center">
          <Link
            className="mr-2"
            to={`/drivers/edit/${e._id}`}
            title="Update Driver"
          >
            <i className="fas fa-edit text-primary" />
          </Link>
          <Link className="mr-2" to={`/drivers/details/${e._id}`}>
            <i className="fas fa-eye text-secondary" />
          </Link>
          {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
        </div>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  const auth = useSelector((state) => state.auth);
  const drivers = useSelector((state) => state.drivers);

  const { isLoading, list } = drivers;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(asyncGetDrivers());
  }, []);

  return (
    <>
      <Table
        dataSource={list.filter((x) => x.verification == "Pending")}
        isLoading={isLoading}
        columns={columns}
      />
    </>
  );
};
export default DriversPendingTable;
