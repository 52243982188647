import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  useGetDriverApplicationDetailsQuery,
  useGetVerifyDocumentMutation,
  useGetVerifyDriverUpdateMutation,
  useGetVerifyVehiclesUpdateMutation,
} from "../../redux/services/applicationAPI";
import { useParams } from "react-router-dom";
import { BUCKET_PUBLIC_URL } from "../../constants";
import moment from "moment";

import VerificationDocument from "./verificationDocument";
import VerifyVehicleModal from "./VerifyVehicleModal";
import ApplicationAccordion from "./applicationAccordion";
import VerifyDriverModal from "./VerifyDriverModal";
import Badge from "react-bootstrap/Badge";
import { notifyFailure, notifySuccess } from "../../components/toast";

function EditApplication() {
  const { id } = useParams();

  const [show, setShow] = useState(false);
  const [verifyDriverDocumentModal, setVerifyDriverDocumentModal] =
    useState(false);
  const [verifyVehicleModal, setVerifyVehicleModal] = useState(false);

  const handleClose = () => setShow(false);
  const verifyDoucmenthandleClose = () => setVerifyDriverDocumentModal(false);

  const { data } = useGetDriverApplicationDetailsQuery(id);
  const [verifyDocumentValue, setVerifyDocumentValue] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});

  const verifyDocumentValueHandler = (event) => {
    const { name, value } = event.target;
    setVerifyDocumentValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [verifyDocumentForm] = useGetVerifyDocumentMutation();

  const submitHandler = async () => {
    try {
      await verifyDocumentForm({
        payload: verifyDocumentValue,
        id: verifyDriverDocumentModal.documentId,
      }).unwrap();
      notifySuccess("verification successfully completed");
      setVerifyDriverDocumentModal(false);
    } catch (error) {
      notifyFailure(error.data.message);
    }
  };

  const vehicleDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    setVehicleDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [verifyVehiclesForm] = useGetVerifyVehiclesUpdateMutation();

  const submitVehicleHandler = async () => {
    try {
      await verifyVehiclesForm({
        payload: vehicleDetails,
        id: verifyVehicleModal,
      }).unwrap();
      notifySuccess("verification successfully completed");
      setVerifyVehicleModal(false);
    } catch (error) {
      notifyFailure(error.data.message);
      console.log("Invalid username or password", error);
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());
  const minDate = tomorrow.toISOString().split("T")[0];

  const [driverDetails, setDriverDetails] = useState({});
  const [verifyDriverModal, setVerifyDriverModal] = useState(false);

  const driverDetailsChangeHandler = (event) => {
    const { name, value } = event.target;
    setDriverDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [verifyDetailsForm] = useGetVerifyDriverUpdateMutation();

  const submitDriverHandler = async () => {
    try {
      await verifyDetailsForm({
        payload: driverDetails,
        id: verifyDriverModal,
      }).unwrap();
      notifySuccess("Driver verified successfully!");
      setVerifyDriverModal(false);
    } catch (error) {
      notifyFailure(error.data.message);
      console.log("err=>", error);
    }
  };

  return (
    <>
      <div className="row application_texts">
        <div className="col-xl-9 col-lg-8 col-md-7">
          <div className="card ">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-10">
                  <h3>Personal Details </h3>
                </div>
                <div className="col-lg-2 d-flex justify-content-end align-items-center">
                  <Badge
                    bg={
                      data?.personalDetails?.verification === "Pending"
                        ? "warning"
                        : data?.personalDetails?.verification === "Verified"
                        ? "success"
                        : data?.personalDetails?.verification === "Rejected"
                        ? "danger"
                        : "danger"
                    }
                  >
                    {data?.personalDetails?.verification}
                  </Badge>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <img
                      src={`${BUCKET_PUBLIC_URL}/${data?.personalDetails?.profileImageUrl}`}
                      width={120}
                      height={120}
                      alt="Logo"
                      className=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className=" d-flex">
                    <strong>Driving License Number :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?._identityCardNumber}
                    </p>
                  </div>
                  <div className=" d-flex">
                    <strong>Driving License Expiry :</strong>
                    <p className="mx-2" href="#">
                      {moment(
                        data?.personalDetails?.drivingLicenseExpiry,
                        "YYYY-MM-DD"
                      ).format("MM-DD-YYYY")}
                      {}
                    </p>
                  </div>
                  <div className=" d-flex">
                    <strong>Identity Card Number :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.drivingLicenseNumber}
                    </p>
                  </div>

                  <div className=" d-flex">
                    <strong>Identity Card Expiry :</strong>
                    <p className="mx-2" href="#">
                      {moment(
                        data?.personalDetails?._identityCardExpiry,
                        "YYYY-MM-DD"
                      ).format("MM-DD-YYYY")}
                    </p>
                  </div>
                  <div className=" d-flex">
                    <strong>Country :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.country?.name}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className=" d-flex">
                    <strong>Name :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.firstName}{" "}
                      {data?.personalDetails?.lastName}
                    </p>
                  </div>
                  <div className=" d-flex">
                    <strong>Gender :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.gender}
                    </p>
                  </div>

                  <div className=" d-flex">
                    <strong>Phone :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.phone}
                    </p>
                  </div>

                  <div className=" d-flex">
                    <strong>Email :</strong>
                    <p className="mx-2" href="#">
                      {data?.personalDetails?.email}
                    </p>
                  </div>
                </div>
              </div>
              {data?.personalDetails?.verification !== "Verified" ? (
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    className=" "
                    style={{ fontSize: 14 }}
                    onClick={() =>
                      setVerifyDriverModal(data?.personalDetails?._id)
                    }
                  >
                    Verify Driver
                  </Button>{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-5 ">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-8">
                  <h5 className="card-title m-0">Vehicle Details</h5>
                </div>
                <div className="col-lg-4 text-right">
                  {data?.vehicleDetails?.verification === "Rejected" || true ? (
                    <>
                      <Badge
                        bg={
                          data?.personalDetails?.verification === "Pending"
                            ? "warning"
                            : data?.personalDetails?.verification === "Verified"
                            ? "success"
                            : data?.personalDetails?.verification === "Rejected"
                            ? "danger"
                            : "danger"
                        }
                      >
                        {data?.vehicleDetails?.verification}
                      </Badge>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="card-body ">
              <div>
                <div className="d-flex mb-2">
                  <div className="user-info-title">
                    <span className="fw-bold mb-0">Color :</span>
                  </div>
                  <div className="d-flex justify-content-around">
                    <p className="mb-0 mx-2"> {data?.vehicleDetails?.color}</p>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="">
                    <span className="fw-bold mb-0">Make : </span>
                  </div>
                  <div className="d-flex justify-content-around">
                    <p className="card-text align-items-baseline mb-0 mx-3">
                      {data?.vehicleDetails?.make}
                    </p>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="user-info-title">
                    <span className="card-text user-info-title fw-bold mb-0">
                      Model :
                    </span>
                  </div>
                  <div className="d-flex justify-content-around">
                    <p className="align-items-end text-end mb-0 mx-3">
                      {data?.vehicleDetails?.model}
                    </p>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="">
                    <span className="fw-bold mb-0">Plate Number :</span>
                  </div>
                  <div className="d-flex justify-content-around">
                    <p className="card-text align-items-baseline mb-0 mx-3">
                      {data?.vehicleDetails?.plateNumber}
                    </p>
                  </div>
                </div>
              </div>

              {data?.vehicleDetails?.verification !== "Verified" ? (
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    className=""
                    style={{ fontSize: 14 }}
                    onClick={() =>
                      setVerifyVehicleModal(data?.vehicleDetails?._id)
                    }
                  >
                    Verify Vehicle
                  </Button>{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ApplicationAccordion
        {...{ setShow, data, setVerifyDriverDocumentModal }}
      />

      <Modal className="modal_changes" show={show} onHide={handleClose}>
        <img src={show} width={600} height={600} alt="Logo" className="my-2" />
      </Modal>

      <VerificationDocument
        {...{
          verifyDriverDocumentModal,
          verifyDoucmenthandleClose,
          verifyDocumentValueHandler,
          verifyDocumentValue,
          submitHandler,
          minDate,
        }}
      />

      <VerifyDriverModal
        {...{
          data: driverDetails,
          open: verifyDriverModal,
          onChange: driverDetailsChangeHandler,
          onClose: () => setVerifyDriverModal(false),
          submitHandler: submitDriverHandler,
        }}
      />
      <VerifyVehicleModal
        {...{
          data: vehicleDetails,
          open: verifyVehicleModal,
          onChange: vehicleDetailsChangeHandler,
          onClose: () => setVerifyVehicleModal(false),
          submitHandler: submitVehicleHandler,
        }}
      />
    </>
  );
}

export default EditApplication;
