import TableHead from "./tableHead";
import TablePagination from "./tablePagination";
import TableRow from "./tableRow";
import DataNotFound from "../../assets/images/table-data-not-found.jpg";
import { useEffect, useState } from "react";

function TableV2({
  title,
  columns,
  dataSource,
  onRowClick,
  isLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  totalPages,
  totalEntries,
  searchValueHandler,
  searchDataHandlerOnTable,
}) {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="card-body w-100">
      <div
        id="dataTableBuilder_wrapper"
        className="dataTables_wrapper no-footer w-100"
      >
        {" "}
        <TableHead
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          setCurrentPage={setCurrentPage}
          searchValueHandler={searchValueHandler}
        />
        {/* <h2>{title}</h2> */}
        {isLoading &&
        dataSource &&
        Array.isArray(dataSource) &&
        dataSource.length == 0 ? (
          <div className="card">
            <img
              width="40%"
              className="mx-auto"
              src="https://hackernoon.com/images/0*4Gzjgh9Y7Gu8KEtZ.gif"
              alt="Loading..."
            />
          </div>
        ) : dataSource &&
          Array.isArray(dataSource) &&
          dataSource.length == 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <img
              src={DataNotFound}
              width="200"
              className="mx-auto"
              alt="No Data Found :("
            />
            <p>Data not found</p>
          </div>
        ) : (
          <div className="table-responsive my-3">
            <div
              id="dataTableBuilder_processing"
              className="dataTables_processing"
              style={{ display: "none" }}
            >
              Processing...
            </div>
            <table
              className="table w-100 dataTable no-footer"
              id="dataTableBuilder"
              role="grid"
              aria-describedby="dataTableBuilder_info"
              // style={{ width: 1171 }}
            >
              <thead>
                <tr role="row">
                  <th
                    title="No"
                    // width={60}
                    // className="sorting"
                    rowSpan={1}
                    colSpan={1}
                    // style={{ width: 60 }}
                    aria-label="No"
                  >
                    {" "}
                    S.No
                  </th>
                  {columns && Array.isArray(columns) && columns.length > 0
                    ? columns.map((x, i) => (
                        <th
                          title="No"
                          // width={60}
                          // className="sorting"
                          rowSpan={1}
                          colSpan={1}
                          // style={{ width: 60 }}
                          aria-label="No"
                          key={i}
                        >
                          {x.displayName}
                        </th>
                      ))
                    : null}
                </tr>
              </thead>
              <tbody>
                {dataSource &&
                Array.isArray(dataSource) &&
                dataSource.length > 0
                  ? dataSource.map((rowData, i) => (
                      <TableRow
                        srNo={indexOfFirstItem + i + 1}
                        key={i}
                        index={i}
                        rowData={rowData}
                        columns={columns}
                      />
                    ))
                  : null}
              </tbody>
            </table>

            <TablePagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={dataSource.length}
              onPageChange={handlePageChange}
              totalPages={totalPages}
              totalEntries={totalEntries}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default TableV2;
