import React, { useEffect, useState } from "react";
import moment from "moment";
import MapWithRoute from "../../components/mapComponents/mapWithRoute";
import PageSubTitle from "../../components/pageSubTitle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { asyncGetRideDetails } from "../../redux/slices/rideSlice";
import { formatRideTime } from "../../utils/formatTime";
import RatingComponent from "../../components/RatingComponent";

const RideDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [directions, setDirections] = useState(null);
  const rides = useSelector((state) => state.rides);
  const { rideDetails } = rides;
  const { ride } = rideDetails;

  const [filteredWaypointsToPickup, setFilteredWaypointsToPickup] =
    useState(null);

  useEffect(() => {
    dispatch(asyncGetRideDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    const driverCoordinatesToPickup = rideDetails?.driverWayPointsToPickup?.map(
      (x) => x.coordinates
    );
    setFilteredWaypointsToPickup(
      driverCoordinatesToPickup?.filter((x) => x[0] !== 0)
    );
  }, [rideDetails.driverWayPointsToPickup]);

  return (
    <div className="px-4 py-2 border-top-0 emp-dashboard">
      <PageSubTitle pageTitle="Ride Details" />
      {ride ? (
        <div className="container-fluid  m-0 p-0">
          <div className="card">
            {/* <div className="card-header">
            <h3 className="text-center">Ride Summary</h3>
          </div> */}
            <div className="card-body">
              <h6 className="card-title py-3">
                {rideDetails.ride.status
                  ? `The ride is ${rideDetails.ride.status}`
                  : ""}{" "}
                {rideDetails.ride.status === "Active" && (
                  <>
                    and{" "}
                    {rideDetails.ride.route.rideStarted
                      ? "is in progress."
                      : rideDetails.ride.route.passengerPicked
                      ? "the passenger is picked."
                      : rideDetails.ride.route.reachedAtPickup
                      ? "the driver has reacthed to the pickup!"
                      : ""}
                  </>
                  // rideDetails.ride.route.reachedAtPickup
                  // ? "The driver has reacthed to the pickup!"
                )}
              </h6>{" "}
              {/* <p className="card-text">Your trip has ended.</p> */}
              {rideDetails.ride.status === "completed" && (
                <MapWithRoute
                  startLocation={
                    rideDetails.driverWayPointsToPickup[0]
                      ? rideDetails.driverWayPointsToPickup[0].coordinates
                      : []
                  }
                  pickupLocation={
                    rideDetails.wayPointsFromPickupToDropoff[0]
                      ? rideDetails.wayPointsFromPickupToDropoff[0].coordinates
                      : ride.route.pickup?.location.coordinates
                  }
                  dropoffLocation={
                    rideDetails.wayPointsFromPickupToDropoff[
                      rideDetails.wayPointsFromPickupToDropoff.length - 1
                    ]
                      ? rideDetails.wayPointsFromPickupToDropoff[
                          rideDetails.wayPointsFromPickupToDropoff.length - 1
                        ].coordinates
                      : ride.route.dropoff?.location.coordinates
                  }
                  directions={directions}
                  setDirections={setDirections}
                  rideWaypoints={rideDetails.wayPointsFromPickupToDropoff}
                  waypointsToPickup={filteredWaypointsToPickup}
                  // waypoints2={[
                  //   { lat: 37.7749, lng: -122.4194 },
                  //   { lat: 37.8049, lng: -122.4302 },
                  // ]}
                />
              )}
              <div className="row py-3">
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Passenger:</strong> {ride?.passenger?.firstName}{" "}
                    {ride?.passenger?.lastName}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Booking Time:</strong>{" "}
                    {moment(ride.bookingTime).format("DD MMM, YYYY @ hh:mm a")}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Estimated Pickup:</strong>{" "}
                    {ride.rideRequest?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Estimated Dropoff:</strong>{" "}
                    {ride.rideRequest?.dropoff?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Actual Pickup:</strong>{" "}
                    {ride.route?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Actual Dropoff:</strong>{" "}
                    {ride.route?.pickup?.address?.formattedAddress}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Arrival Time:</strong>{" "}
                    {ride.route.reachedAtPickup
                      ? moment(
                          ride.rideActions.find(
                            (x) => x.type === "reachedAtPickup"
                          )?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Pickup Time:</strong>{" "}
                    {ride.route.passengerPicked
                      ? moment(
                          ride.rideActions.find(
                            (x) => x.type === "passengerPicked"
                          )?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Dropoff Time:</strong>{" "}
                    {ride.route.rideEnded
                      ? moment(
                          ride.rideActions.find((x) => x.type === "rideEnded")
                            ?.timestamp
                        ).format("DD MMM, YYYY @ hh:mm a")
                      : "N/A"}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Vehicle:</strong> {ride.vehicle?.color}{" "}
                    {ride.vehicle?.make} {ride.vehicle?.model}{" "}
                    {ride.vehicle?.year}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Waiting Time:</strong>{" "}
                    {formatRideTime(ride.route.waitingTimeInMinutes * 60)}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Ride Time:</strong>{" "}
                    {formatRideTime(
                      ride.route.pickupToDropoffTimeInMinutes * 60
                    )}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Distance till Pickup:</strong>{" "}
                    {ride.route.distanceFromStartToPickup} km
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>
                      Ride Distance {`( Pickup to Dropoff )`}:
                    </strong>{" "}
                    {ride.route.distanceFromPickupToDropoff} km
                  </div>
                </div>{" "}
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Fare:</strong> {ride.route.fareDetails?.currency}{" "}
                    {ride.route.fareDetails?.totalFare}{" "}
                    {`( ${ride.route.fareDetails?.fareWithoutCommission} without commission )`}
                  </div>
                </div>
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Rating by passenger:</strong>{" "}
                    {rideDetails.ride.ratingDetails.passengerRating ? (
                      <div className="mt-2">
                        <RatingComponent
                          rating={
                            rideDetails.ride.ratingDetails.passengerRating
                              .rating
                          }
                          iconSize="28px"
                          readOnly
                        />
                        {rideDetails.ride.ratingDetails.passengerRating
                          .feedback !== "" && (
                          <p className="mt-2">
                            <strong>Comment:</strong>{" "}
                            {
                              rideDetails.ride.ratingDetails.passengerRating
                                .feedback
                            }
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="mt-3">Not rated yet!</div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 p-2">
                  <div className="p-3 card h-100">
                    {" "}
                    <strong>Rating by driver:</strong>{" "}
                    {rideDetails.ride.ratingDetails.driverRating ? (
                      <div className="mt-2">
                        <RatingComponent
                          rating={
                            rideDetails.ride.ratingDetails.driverRating.rating
                          }
                          iconSize="28px"
                          readOnly
                        />
                        <p className="mt-2">
                          <strong>Comment:</strong>{" "}
                          {rideDetails.ride.ratingDetails.driverRating.feedback}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">Not rated yet!</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Other ride details */}
            <div className="card-footer text-muted text-center">
              <p>Thank you for choosing our service!</p>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RideDetails;
