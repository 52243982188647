import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";

import { useDispatch, useSelector } from "react-redux";
import { asyncGetDriversLocation } from "../../redux/slices/driverSlice";
import moment from "moment";

import { Link } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

function DriversLocation() {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [defaultCenter, setDefaultCenter] = useState({});
  const drivers = useSelector((state) => state.drivers);
  const { driverLocations } = drivers;
  const { list, isLoading } = driverLocations;

  const successCallback = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;

    setDefaultCenter({
      lat: lat,
      lng: lon,
    });
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  const dispatch = useDispatch();

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const locationHandler = () => {
    dispatch(asyncGetDriversLocation());
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    dispatch(asyncGetDriversLocation());
  }, [dispatch]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const closeInfoWindow = () => {
    setSelectedMarker(null);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: libraries,
  });

  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }

  return (
    <>
      <div className="card card-block card-stretch card-height">
        {" "}
        <div className="card-header d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title mb-0">Cabs List</h4>
          </div>

          <CustomButton
            title={"Refresh"}
            onClick={locationHandler}
            isLoading={isLoading}
          ></CustomButton>
        </div>
      </div>

      <GoogleMap mapContainerStyle={mapStyles} zoom={12} center={defaultCenter}>
        {list.map((markerData, index) =>
          markerData.driver ? (
            <MarkerF
              key={index}
              // position={marker.location.coordinates}
              position={{
                lat: markerData.location.coordinates[1],
                lng: markerData.location.coordinates[0],
              }}
              onClick={() => handleMarkerClick(index)}
              icon={{
                url: markerData.hasActiveRide
                  ? "/assets/images/driver-onride.svg"
                  : markerData.isOnline
                  ? "/assets/images/driver-online.svg"
                  : "/assets/images/driver-offline.svg",
                scaledSize: { width: 30, height: 40 },
              }}
              // icon={<FaCarAlt />}
            >
              {" "}
              {selectedMarker === index && (
                <InfoWindowF
                  // position={{
                  //   lat: selectedMarker.location.coordinates[0],
                  //   lng: selectedMarker.location.coordinates[1],
                  // }}
                  onCloseClick={closeInfoWindow}
                >
                  <div>
                    {markerData.hasActiveRide ? (
                      <h6 className="text-success">Ride in progress</h6>
                    ) : markerData.isOnline ? (
                      <h6 className="text-blue">Driver is Online</h6>
                    ) : (
                      <h6 className="text-danger">Driver is Offline</h6>
                    )}

                    <p className="my-1">
                      {" "}
                      <b>Driver Name: </b>
                      {`${
                        markerData.driver ? markerData.driver.firstName : ""
                      } ${markerData.driver ? markerData.driver.lastName : ""}`}
                    </p>

                    <p className="my-1">
                      <b>Phone: </b>
                      {markerData.driver ? markerData.driver.phone : ""}{" "}
                    </p>
                    <p className="my-1">
                      <b>Email: </b>
                      {markerData.driver ? markerData.driver.email : ""}{" "}
                    </p>
                    <p className="my-1">
                      <b>Updated At: </b>
                      {markerData.updatedAt
                        ? moment(markerData.updatedAt).format(
                            "hh:mm a @ DD MMM, YYYY"
                          )
                        : ""}{" "}
                    </p>
                    <Link
                      to={`/drivers/details/${markerData.driver._id}`}
                      className="text-primary"
                    >
                      <b>View Details</b>
                    </Link>
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
          ) : (
            <></>
          )
        )}
      </GoogleMap>
    </>
  );
}

export default DriversLocation;
