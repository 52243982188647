import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";

function CountryForm({ submitHandler, isLoading, changeHandler, formData }) {
  return (
    <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
      <div
        id="leads-table_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <form onSubmit={submitHandler} className="p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="currency">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  id="minimum_fair"
                  placeholder="name"
                  name="name"
                  onChange={changeHandler}
                  value={formData.name}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="minimum_fair">
                  Short Name
                </label>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  id="minimum_fair"
                  placeholder="short name"
                  name="shortName"
                  onChange={changeHandler}
                  value={formData.shortName}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group my-3">
                <label className="f-14 text-dark-grey mb-12" for="base_fair">
                  Code
                </label>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  id="base_fair"
                  placeholder="code"
                  name="code"
                  onChange={changeHandler}
                  value={formData.code}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  for="per_kilo_charge"
                >
                  Currency
                </label>
                <input
                  type="text"
                  className="form-control height-35 f-14"
                  id="per_kilo_charge"
                  placeholder="currency"
                  name="currency"
                  onChange={changeHandler}
                  value={formData.currency}
                  required
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div
                className="form-group my-3"
                style={{ marginTop: "1.5em !important" }}
              >
                <CustomButton
                  className="btn-primary rounded f-14 p-2 mr-3"
                  isLoading={isLoading}
                  title="Submit"
                  type="submit"
                />
                {/* <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Submit
                </button> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CountryForm;
