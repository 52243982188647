import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CustomSelect from "../../components/CustomSelect";
import CustomSelectAsync from "../../components/CustomSelectAsync";

import axiosPrivate from "../../services/api";
import DropzoneUpload from "../../components/DropzoneUpload";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import { asyncUpdateDriverDocument } from "../../redux/slices/driverSlice";
import moment from "moment";
import { Link } from "react-router-dom";

function EditDriverDocument() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requiredDocumentsList, setRequiredDocumentsList] = useState([]);
  const [driverInitialValue, setDriverInitialValue] = useState(null);
  const [documentTypeInitialValue, setDocumentTypeInitialValue] =
    useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [formData, setFormData] = useState(null);
  const drivers = useSelector((state) => state.drivers);

  const { documents } = drivers;

  const { isLoading, list } = documents;

  // Define a function to load driver options from an API
  const loadDriverOptions = async (inputValue) => {
    // Make an API request to fetch driver options based on the inputValue
    const { data } = await axiosPrivate.get(
      `/drivers/search?name=${inputValue}`
    );

    // Format the response data into options for the Select component
    const options = data.map((driver) => ({
      label: `${driver.firstName} ${driver.lastName}`, // Replace with the driver's name property
      value: driver._id, // Replace with the driver's unique identifier property
    }));

    return options;
  };

  const getRequiredDocumentsList = async () => {
    try {
      const { data } = await axiosPrivate.get(`/requiredDocuments`);
      setRequiredDocumentsList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const postDriverDocumentsHandler = () => {
    dispatch(
      asyncUpdateDriverDocument({
        id,
        data: { ...formData, driver: formData.driver },
        callback: () => navigate(-1),
      })
    );
  };

  const downloadImage = (imageUrl, fileName) => {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = imageUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const documentFound = list.find((x) => x._id === id);
    if (documentFound) {
      if (documentFound.driver) {
        setDriverInitialValue({
          label: `${documentFound.driver.firstName} ${documentFound.driver.lastName}`,
          value: documentFound.driver._id,
        });
      }
      setDocumentTypeInitialValue({
        label: documentFound.documentType.name,
        value: documentFound.documentType._id,
      });

      setFormData({
        // documentFor: documentFound.documentFor._id,
        documentType: documentFound.documentType._id,

        expirationDate: moment(documentFound.expirationDate).format(
          "YYYY-MM-DD"
        ),
        frontSideUrl: documentFound.frontSideUrl,
        backSideUrl: documentFound.backSideUrl,
        status: documentFound.status,
        driver: documentFound.driver?._id,
        modelNameOfTheDocumentFor: documentFound.modelNameOfTheDocumentFor,
        rejectReason: documentFound.rejectReason,
      });

      getRequiredDocumentsList();
    } else {
      navigate(-1);
    }
  }, [navigate, list, id]);

  useEffect(() => {
    if (requiredDocumentsList.length > 0 && formData && formData.documentType)
      setSelectedDocumentType(
        requiredDocumentsList.find((x) => x._id === formData.documentType)
      );
  }, [formData, requiredDocumentsList]);

  return (
    <div>
      {formData && (
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Edit Driver Document</h4>
                  </div>
                </div>
                <div className="card-body pb-5">
                  <div className="new-user-info">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label
                          htmlFor="driver_id"
                          className="form-control-label"
                        >
                          Select Driver <span className="text-danger">*</span>
                        </label>

                        <CustomSelectAsync
                          loadOptions={loadDriverOptions}
                          name="driver"
                          placeholder="Search here"
                          onChange={changeHandler}
                          formData={formData}
                          value={formData.driver}
                          initialValue={driverInitialValue}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label
                          htmlFor="document_id"
                          className="form-control-label"
                        >
                          Select Document <span className="text-danger">*</span>
                        </label>
                        <CustomSelect
                          name="documentType"
                          placeholder="Select document"
                          options={requiredDocumentsList.map((x) => ({
                            label: x.name,
                            value: x._id,
                          }))}
                          onChange={changeHandler}
                          initialValue={documentTypeInitialValue}
                        />
                      </div>
                      {selectedDocumentType?.hasExpiry && (
                        <div className="form-group col-md-4">
                          <label
                            className="form-control-label"
                            htmlFor="expire_date"
                          >
                            Expiry Date{" "}
                            <span
                              className="text-danger"
                              id="has_expiry_date"
                            />{" "}
                          </label>
                          <input
                            className="form-control min-datepicker flatpickr-input"
                            placeholder="Expiry Date"
                            type="date"
                            onChange={changeHandler}
                            name="expirationDate"
                            value={formData.expirationDate}
                          />
                        </div>
                      )}
                      <div className="form-group col-md-4">
                        <label
                          htmlFor="is_verified"
                          className="form-control-label"
                        >
                          Verification <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control "
                          name="status"
                          onChange={changeHandler}
                          value={formData.status}
                        >
                          <option value="Peding">Pending</option>
                          <option value="Verified">Verified</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                      </div>

                      {formData.status === "Rejected" ? (
                        <div className="form-group col-md-4">
                          <label
                            htmlFor="driver_id"
                            className="form-control-label"
                          >
                            Reject Reason <span className="text-danger">*</span>
                          </label>
                          <textarea
                            style={{ lineHeight: "15px" }}
                            className="form-control min-datepicker flatpickr-input"
                            onChange={changeHandler}
                            name="rejectReason"
                            value={formData.rejectReason}
                          ></textarea>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label
                          className="form-control-label"
                          htmlFor="driver_document"
                        >
                          Upload Front Side{" "}
                          <span
                            className="text-danger"
                            id="document_required"
                          />{" "}
                        </label>
                        <DropzoneUpload
                          name="frontSideUrl"
                          directory={`driver-uploads/${formData?.documentFor}/${selectedDocumentType?._id}/front/`}
                          onChange={changeHandler}
                          isEnabled={
                            formData.documentFor && formData.documentType
                          }
                          disableReason="Please select driver and document type first!"
                        />
                        <span className="selected_file" />
                      </div>
                      {selectedDocumentType?.hasBackSide && (
                        <div className="form-group col-md-4">
                          <label
                            className="form-control-label"
                            htmlFor="driver_document"
                          >
                            Upload Back Side{" "}
                            <span
                              className="text-danger"
                              id="document_required"
                            />{" "}
                          </label>
                          <DropzoneUpload
                            name="backSideUrl"
                            directory={`driver-uploads/${formData?.driver}/${selectedDocumentType?._id}/back/`}
                            onChange={changeHandler}
                            isEnabled={
                              formData.documentFor && formData.documentType
                            }
                            disableReason="Please select driver and document type first!"
                          />
                          <span className="selected_file" />
                        </div>
                      )}
                      {formData?.frontSideUrl !== "" && (
                        <div className="form-group col-md-4">
                          <label
                            className="form-control-label"
                            htmlFor="driver_document"
                          >
                            Uploaded documents
                            <span
                              className="text-danger"
                              id="document_required"
                            />{" "}
                          </label>
                          <div className="d-flex gap-2">
                            <div className="m-2">
                              <h5>Front side:</h5>
                              <img
                                src={formData.frontSideUrl}
                                alt="Document Front"
                                className="img-fluid"
                                width="300px"
                              />
                              <div>
                                <Link
                                  onClick={() =>
                                    downloadImage(
                                      formData.frontSideUrl,
                                      "document"
                                    )
                                  }
                                  to=""
                                >
                                  Download
                                </Link>
                              </div>
                            </div>
                            {formData.backSideUrl &&
                              formData.backSideUrl !== "" && (
                                <div className="m-2">
                                  <h5>Back side:</h5>
                                  <img
                                    src={formData.backSideUrl}
                                    alt="Document Back"
                                    className="img-fluid"
                                    width="300px"
                                  />
                                  <div>
                                    <Link
                                      onClick={() =>
                                        downloadImage(
                                          formData.backSideUrl,
                                          "document"
                                        )
                                      }
                                      to=""
                                    >
                                      Download
                                    </Link>
                                  </div>{" "}
                                </div>
                              )}
                          </div>
                          {/* <div>
                          {formData.frontSideUrl && formData.frontSideUrl ? (
                            <img src={formData.frontSideUrl} height={150} />
                          ) : (
                            <></>
                          )}
                          {selectedDocumentType?.hasBackSide &&
                          formData.backSideUrl &&
                          formData.backSideUrl ? (
                            <img src={formData.backSideUrl} height={150} />
                          ) : (
                            <></>
                          )}
                        </div> */}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <CustomButton
                      className="btn btn-md btn-primary float-right"
                      onClick={postDriverDocumentsHandler}
                      title="Submit"
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                {/* <hr /> */}
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default EditDriverDocument;
