import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import signinImg from "../../assets/images/Frame.png";
// import signinImg from '../../assetsgeneral/images/Frame.png'
// import CabtifyLogo from "../../assets/images/cabtify-full-white.png";
// import signinLogo from '../../assetsgeneral/images/CONNECT CARE TRANS-04.png'
// import signinLogo from '../../assetsgeneral/images/CONNECTCARE_WhiteLogo.png'
import { asyncSignIn } from "../../redux/slices/authSlice";
import DotsLoader from "../../components/loading/DotsLoader";

const LoginPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { isLoading, userInfo, tokens } = auth;

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(asyncSignIn(data));
  };

  useEffect(() => {
    if (userInfo && tokens) navigate("/dashboard");
  }, [userInfo]);

  if (auth.tokens) {
    return;
  }

  return (
    <div className="px-2 py-5 px-md-5 mt-4 mb-4 text-lg-start">
      <div className="container">
        <div className="row gx-lg-5 align-items-center">
          <div className="col-lg-7 mb-5 mb-lg-0">
            <div className="row">
              <div className="sig-img">
                <img src={signinImg} width="100%" alt="" id="sigimg" />
              </div>
            </div>
          </div>

          <div className="col-lg-5 mb-lg-0">
            <div className="card-2 bg-brand">
              <div className="card-body py-5 px-md-5">
                <form onSubmit={handleSubmit}>
                  <div className="text-center p-4">
                    <Link to="">
                      <img
                        src={"/assets/images/logo-white.png"}
                        height={80}
                        alt=""
                        id="logsedf"
                      />
                    </Link>
                  </div>
                  <div className="text-white mb-3">
                    <h4>Sign In</h4>
                  </div>
                  {/* Email input */}
                  <div className="col-md-12 mb-4">
                    <label
                      className="form-label label-text"
                      htmlFor="form3Example3"
                    >
                      Email address or Username
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-envelope icon"></i>
                        </span>
                      </div>
                      <input
                        className="form-control"
                        placeholder="Your Email"
                        value={data.email}
                        onChange={handleChange}
                        name="email"
                        aria-label="Your Email"
                        id="form3Example3"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  {/* Username input */}
                  {/* {
                                isEmail === false &&
                                <div className="col-md-12 mb-4">
                                    <label className="form-label label-text" htmlFor="form3Example3">Username</label>
                                    <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                        <i className="fa fa-user icon"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="Your Username" value={values.username} onChange={handleChange('username')} aria-label="Your Email" id="form3Example3" aria-describedby="basic-addon1"/>
                                    </div>
                                    <div className='text-center'>
                                        <button style={{backgroundColor: "#F69B31"}} onClick={changeEmailUsername}>
                                            (Use Email Instead)
                                        </button>
                                    </div>
                                </div>
                            } */}
                  {/* <!-- Password input --> */}
                  <div className="col-md-12 mb-4">
                    <label
                      className="form-label label-text"
                      htmlFor="form3Example3"
                    >
                      Password
                    </label>
                    {/* <label className="form-label label-text" htmlFor="form3Example3" style={{color: "#fff"}}>Password</label> */}
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-key icon"></i>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Your Password"
                        value={data.password}
                        onChange={handleChange}
                        name="password"
                        aria-label="Your Password"
                        id="form3Example3"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  {/* <!-- Submit button --> */}
                  {auth.error && (
                    <span className="text-danger"> *Invalid Credentials*</span>
                  )}
                  <div className="d-grid mb-4 px-3">
                    <button
                      type="submit"
                      className="btn btn-secondary w-100"
                      style={{ padding: "10px 40px" }}
                      disabled={isLoading}
                    >
                      {isLoading ? <DotsLoader /> : "Login"}
                      {/* <LoadingComponent /> */}
                      {/* Sign In */}
                    </button>
                  </div>

                  {/* <!-- signup --> */}
                  {/* <div className="row">
                                <div className="col-md-12">
                                <div className="text-center mb-4">
                                    <p>Signup as User <Link to="" style={{color: "#283E4B"}}>SIGNUP</Link> </p>
                                </div>
                                </div>
                            </div> */}

                  {/* <!-- Register buttons --> */}
                  {/* <div className="text-center">
                                <p>or sign up with:</p>
                                <button type="button" className="btn btn-floating mx-1">
                                <i className="fa fa-facebook-f"></i> sign up with
                                </button>
                                <button type="button" className="btn btn-floating mx-1">
                                <i className="fa fa-google"></i> sign up with
                                </button>
                            </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
