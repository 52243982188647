import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetDriversEarning } from "../../redux/slices/driverSlice";
import { BUCKET_PUBLIC_URL } from "../../constants";

function DriversEarningsTable() {
  const columns = [
    {
      displayName: "Driver",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <img
            width={40}
            height={40}
            src={`${BUCKET_PUBLIC_URL}/${e.driver.profileImageUrl}`}
            className="rounded-circle object-fit-cover"
          />
          <p className="ml-2 mb-0">
            {e.driver.firstName} {e.driver.lastName}
          </p>
        </div>
      ),
      searchable: true,
    },
    {
      displayName: "Total Earnings",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalEarnings}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Total Cash Collected",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalCashAmount}
        </>
      ),
      searchable: true,
    },

    {
      displayName: "Received Wallet Amount",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalWalletAmount}
        </>
      ),
    },
  ];

  const driversEarnings = useSelector((state) => state.drivers.driversEarnings);
  const auth = useSelector((state) => state.auth);

  const { isLoading, list } = driversEarnings;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetDriversEarning());
  }, [auth.country]);

  return (
    <div>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
    </div>
  );
}

export default DriversEarningsTable;
