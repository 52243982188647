import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CustomSelectAsync from "../../components/CustomSelectAsync";
import axiosPrivate from "../../services/api";
import CustomSelect from "../../components/CustomSelect";
import { asyncCabsUpdate } from "../../redux/slices/cabSlice";

function EditCab() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formData, setFormData] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [driverInitialValue, setDriverInitialValue] = useState(null);
  const [categoryInitialValue, setCategoryInitialValue] = useState(null);

  const cabs = useSelector((state) => state.cabs);

  const { list } = cabs;

  // Define a function to load driver options from an API
  const loadDriverOptions = async (inputValue) => {
    // Make an API request to fetch driver options based on the inputValue
    const { data } = await axiosPrivate.get(
      `/drivers/search?name=${inputValue}`
    );

    // Format the response data into options for the Select component
    const options = data.map((driver) => ({
      label: `${driver.firstName} ${driver.lastName}`, // Replace with the driver's name property
      value: driver._id, // Replace with the driver's unique identifier property
    }));

    return options;
  };

  const getCategoriesList = async () => {
    try {
      const { data } = await axiosPrivate.get(`/vehicleCategories`);
      setCategoriesList(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const cabFound = list.find((x) => x._id === id);

    console.log("cabFound", cabFound);
    if (cabFound) {
      setDriverInitialValue({
        value: cabFound?.driver?._id,
        label: `${cabFound?.driver?.firstName} ${cabFound?.driver?.lastName}`,
      });
      setCategoryInitialValue({
        value: cabFound?.category?._id,
        label: `${cabFound?.category?.name}`,
      });

      setFormData({
        make: cabFound.make,
        model: cabFound.model,
        year: cabFound.year,
        color: cabFound.color,
        // engineCC: cabFound.engineCC,
        plateNumber: cabFound.plateNumber,
        driver: cabFound?.driver?._id,
        category: cabFound?.category?._id,
        status: cabFound.status,
        verification: cabFound.verification,
      });
    } else {
      navigate("/cabs");
    }
    getCategoriesList();
  }, [navigate, list, id]);

  const submitHandler = (event) => {
    dispatch(
      asyncCabsUpdate({
        obj: { ...formData },
        id: id,
        callback: () => {
          navigate("/cabs");
        },
      })
    );
  };

  const changeHandler = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "radio") {
      // Handle radio button changes
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      // Handle other input changes
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  return (
    <div>
      {formData && (
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Update Cab</h4>
                </div>
              </div>
              <div className="card-body">
                <div className="form-group">
                  {/* <div className="crm-profile-img-edit position-relative">
                    <img
                      src="https://meetmighty.com/mobile/mighty-taxi/storage/37/ic_economy.png"
                      alt="Cab-Profile"
                      className="crm-profile-pic rounded-circle avatar-100"
                    />
                  </div> */}
                  <div className="img-extension mt-3">
                    {/* <div className="d-inline-block align-items-center">
                    <span>Only</span>
                    <a href="javascript:void();">.png</a>
                    <a href="javascript:void();">.jpg</a>
                    <a href="javascript:void();">.jpeg</a>
                    <a href="javascript:void();">.gif</a>
                    <span>Allowed</span>
                  </div> */}
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    <strong>Status:</strong>
                  </label>
                  <div className="grid">
                    <div className="form-check g-col-6">
                      <input
                        className="form-check-input"
                        id="status-active"
                        name="status"
                        type="radio"
                        value="Active" // Set the value for the "Active" option
                        checked={formData.status === "Active"} // Check if this radio button is selected
                        onChange={changeHandler} // Call the handler on change
                      />
                      <label
                        htmlFor="status-active"
                        className="form-check-label"
                      >
                        Active
                      </label>
                    </div>
                    <div className="form-check g-col-6">
                      <input
                        className="form-check-input"
                        id="status-inactive"
                        name="status"
                        type="radio"
                        value="Inactive" // Set the value for the "Active" option
                        checked={formData.status === "Inactive"} // Check if this radio button is selected
                        onChange={changeHandler} // Call the handler on change
                      />
                      <label
                        htmlFor="status-inactive"
                        className="form-check-label"
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    <strong>Verification:</strong>
                  </label>
                  <div className="grid">
                    <div className="form-check g-col-6">
                      <input
                        className="form-check-input"
                        id="verification-pending"
                        name="verification"
                        type="radio"
                        value="Pending" // Set the value for the "Active" option
                        checked={formData.verification === "Pending"} // Check if this radio button is selected
                        onChange={changeHandler} // Call the handler on change
                      />
                      <label
                        htmlFor="verification-pending"
                        className="form-check-label"
                      >
                        Pending
                      </label>
                    </div>
                    <div className="form-check g-col-6">
                      <input
                        className="form-check-input"
                        id="verification-verified"
                        name="verification"
                        type="radio"
                        value="Verified" // Set the value for the "Active" option
                        checked={formData.verification === "Verified"} // Check if this radio button is selected
                        onChange={changeHandler} // Call the handler on change
                      />
                      <label
                        htmlFor="verification-verified"
                        className="form-check-label"
                      >
                        Verified
                      </label>
                    </div>
                    <div className="form-check g-col-6">
                      <input
                        className="form-check-input"
                        id="verification-rejected"
                        name="verification"
                        type="radio"
                        value="Rejected" // Set the value for the "Active" option
                        checked={formData.verification === "Rejected"} // Check if this radio button is selected
                        onChange={changeHandler} // Call the handler on change
                      />
                      <label
                        htmlFor="verification-rejected"
                        className="form-check-label"
                      >
                        Rejected
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Update Cab Information</h4>
                </div>
                <div className="card-action">
                  <Link
                    to="/cabs"
                    className="btn btn-sm btn-primary"
                    role="button"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="new-user-info">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="make" className="form-control-label">
                        Make <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Make"
                        className="form-control"
                        required=""
                        name="make"
                        type="text"
                        value={formData.make}
                        onChange={changeHandler}
                        id="make"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="model" className="form-control-label">
                        Model <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Model"
                        className="form-control"
                        required=""
                        name="model"
                        type="text"
                        value={formData.model}
                        onChange={changeHandler}
                        id="model"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="year" className="form-control-label">
                        Year <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Year"
                        className="form-control"
                        required=""
                        name="year"
                        type="number"
                        min="1995" // Set the minimum allowed year
                        max="2023" // Set the maximum allowed year
                        step="1" // Set the step (increment) for the input
                        value={formData.year}
                        onChange={changeHandler}
                        id="year"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="color" className="form-control-label">
                        Color <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Color"
                        className="form-control"
                        required=""
                        name="color"
                        type="tel"
                        id="color"
                        value={formData.color}
                        onChange={changeHandler}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label
                        htmlFor="plateNumber"
                        className="form-control-label"
                      >
                        Plate Number <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Plate Number"
                        className="form-control"
                        required=""
                        name="plateNumber"
                        type="text"
                        id="plateNumber"
                        value={formData.plateNumber}
                        onChange={changeHandler}
                      />
                    </div>

                    {/* <div className="form-group col-md-6">
                      <label htmlFor="engineCC" className="form-control-label">
                        Engine CC <span className="text-danger">*</span>
                      </label>
                      <input
                        placeholder="Engine CC"
                        className="form-control"
                        required=""
                        name="engineCC"
                        type="number"
                        id="engineCC"
                        value={formData.engineCC}
                        onChange={changeHandler}
                      />
                    </div> */}
                    <div className="form-group col-md-6">
                      <label htmlFor="driver_id" className="form-control-label">
                        Assigned Driver <span className="text-danger">*</span>
                      </label>

                      <CustomSelectAsync
                        loadOptions={loadDriverOptions}
                        name="driver"
                        placeholder="Search here"
                        onChange={changeHandler}
                        formData={formData}
                        value={formData.driver}
                        initialValue={driverInitialValue}
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label
                        htmlFor="document_id"
                        className="form-control-label"
                      >
                        Cab Category <span className="text-danger">*</span>
                      </label>
                      <CustomSelect
                        name="category"
                        placeholder="Select category"
                        options={categoriesList.map((x) => ({
                          label: x.name,
                          value: x._id,
                        }))}
                        onChange={changeHandler}
                        initialValue={categoryInitialValue}
                      />
                    </div>
                  </div>
                  <hr />
                  <button
                    onClick={submitHandler}
                    className="btn btn-md btn-primary float-right"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditCab;
