import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import sidebarSlice from "./slices/sidebarSlice";
import driverSlice from "./slices/driverSlice";
import passengerSlice from "./slices/passengerSlice";
import userSlice from "./slices/userSlice";
import cabSlice from "./slices/cabSlice";
import rideSlice from "./slices/rideSlice";
import vehicleCategorySlice from "./slices/vehicleCategorySlice";
import rideFareSlice from "./slices/rideFareSlice";
import walletSlice from "./slices/walletSlice";
import documentSlice from "./slices/documentSlice";
import sattlementsSlice from "./slices/SattlementSlice";
import adminDashboardSlice from "./slices/dashboardSlice";
import companySettingSlice from "./slices/settingSlice";
import countrySlice from "./slices/countrySlice";
import NotificationSlice from "./slices/noticationSlice";
import faqsSlice from "./slices/faqsSlice";
import contactSlice from "./slices/contactSlice";
import { rideAPI } from "./services/rideAPI";
import { contactAPI } from "./services/contactAPI";
import { notificationAPI } from "./services/notificationAPI";
import { applicationAPI } from "./services/applicationAPI";
import { paymentAPI } from "./services/paymentAPI";
import { walletAPI } from "./services/walletAPI";
import { flatFeeApi } from "./services/flatFeeApi";
import { userAPI } from "./services/userAPI";

const appReducer = combineReducers({
  auth: authSlice,
  drivers: driverSlice,
  passengers: passengerSlice,
  users: userSlice,
  cabs: cabSlice,
  documents: documentSlice,
  rides: rideSlice,
  vehicleCategories: vehicleCategorySlice,
  rideFares: rideFareSlice,
  sidebar: sidebarSlice,
  wallets: walletSlice,
  sattlements: sattlementsSlice,
  adminDashboard: adminDashboardSlice,
  setting: companySettingSlice,
  countries: countrySlice,
  faqs: faqsSlice,
  contact: contactSlice,
  Notification: NotificationSlice,
  [userAPI.reducerPath]: userAPI.reducer,
  [rideAPI.reducerPath]: rideAPI.reducer,
  [contactAPI.reducerPath]: contactAPI.reducer,
  [notificationAPI.reducerPath]: notificationAPI.reducer,
  [applicationAPI.reducerPath]: applicationAPI.reducer,
  [paymentAPI.reducerPath]: paymentAPI.reducer,
  [walletAPI.reducerPath]: walletAPI.reducer,
  [flatFeeApi.reducerPath]: flatFeeApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type == "auth/logout") {
    // Clear localStorage
    localStorage.removeItem("cabtifyTokens");
    localStorage.removeItem("cabtifyInfo");
    localStorage.removeItem("country");
    // Reset state
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
