import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/logo-icon.png";

import sidebarList from "./MenuSidebar";

function Sidebar() {
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { role } = userInfo;

  const toggleSidebar = (e) => {
    document.body.classList.toggle("sidebar-main");
  };

  const scrollSidebar = (scrollAmount) => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTop += scrollAmount;
    }
  };

  // useEffect(() => {
  //   // Add event listeners to control scrolling
  //   const handleWheel = (event) => {
  //     // Adjust the scroll amount as needed (negative for scroll up, positive for scroll down)
  //     const scrollAmount = event.deltaY;

  //     // Prevent the default scroll behavior
  //     event.preventDefault();

  //     // Scroll the sidebar
  //     scrollSidebar(scrollAmount);
  //   };

  //   // Attach the event listener to the sidebar element
  //   if (sidebarRef.current) {
  //     sidebarRef.current.addEventListener("wheel", handleWheel);

  //     // Use a timeout to check for overflow after a slight delay
  //     setTimeout(() => {
  //       setShowScrollbar(
  //         sidebarRef.current.scrollHeight > sidebarRef.current.clientHeight
  //       );
  //     }, 100); // Adjust the timeout duration as needed
  //   }

  //   return () => {
  //     // Remove the event listener when the component unmounts
  //     if (sidebarRef.current) {
  //       sidebarRef.current.removeEventListener("wheel", handleWheel);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const sidebarElement = sidebarRef.current;

    if (sidebarElement) {
      const handleWheel = (event) => {
        const scrollAmount = event.deltaY;
        event.preventDefault();
        scrollSidebar(scrollAmount);
      };

      sidebarElement.addEventListener("wheel", handleWheel);

      setTimeout(() => {
        setShowScrollbar(
          sidebarElement.scrollHeight > sidebarElement.clientHeight
        );
      }, 100);

      return () => {
        if (sidebarElement) {
          sidebarElement.removeEventListener("wheel", handleWheel);
        }
      };
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className="mm-sidebar sidebar-default">
      <div className="mm-sidebar-logo d-flex align-items-center justify-content-between">
        <a href="/" className="header-logo">
          <img
            src={Logo}
            className="img-fluid mode light-img rounded-normal light-logo site_logo_preview"
            alt="logo"
          />
          <img
            src={Logo}
            className="img-fluid mode dark-img bg-white rounded-normal darkmode-logo site_dark_logo_preview"
            alt="dark-logo"
          />
        </a>
        <div className="side-menu-bt-sidebar" onClick={toggleSidebar}>
          <i className="fas fa-bars wrapper-menu" />
        </div>
      </div>

      <div
        ref={sidebarRef} // Reference to the sidebar element
        className="data-scrollbar scrollbar-macosx"
        data-scroll={1}
        data-scrollbar="true"
        tabIndex={-1}
        style={{ overflow: "hidden", outline: "none" }}
      >
        <div className="scroll-content">
          <nav className="mm-sidebar-menu">
            <ul id="mm-sidebar-toggle" className="side-menu">
              {sidebarList
                ?.filter((item) => item.role.includes(role))
                .map((items, index) => {
                  return (
                    <li className="" key={items?.name}>
                      {" "}
                      {items?.subItems ? (
                        <>
                          <a
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            className=""
                            href={`#${items?.name}`}
                          >
                            <i className={items.icons} />
                            <span> {items?.name} </span>
                            <i
                              className={
                                "fas fa-angle-right mm-arrow-right arrow-active"
                              }
                            />
                            <i
                              className={
                                "fas fa-angle-down mm-arrow-right arrow-hover"
                              }
                            />
                          </a>

                          <ul className="submenu collapse" id={items?.name}>
                            {items?.subItems
                              ?.filter((item) => item.role.includes(role))
                              ?.map((subItems) => (
                                <li
                                  className="sidebar-layout"
                                  key={subItems?.name}
                                >
                                  {" "}
                                  {/* Make sure to add a unique key */}
                                  <NavLink
                                    className="nav-link"
                                    activeclassname=" active"
                                    end
                                    to={subItems.path}
                                  >
                                    <i className={subItems?.icons} />
                                    <span> {subItems?.name} </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </>
                      ) : (
                        <Link className="" to={`${items?.path}`}>
                          <i className={items.icons} />
                          <span> {items?.name} </span>
                        </Link>
                      )}
                    </li>
                  );
                })}

              <li className="accordionItem closeIt">
                <NavLink
                  className="nav-item text-lightest f-15 sidebar-text-color"
                  end
                  to=""
                  onClick={logoutHandler}
                >
                  <i className="fas fa-sign-out-alt"></i>
                  <span className="pl-3">Logout</span>
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="mb-5 pb-2">
            <div className="pt-5 pb-2">
              <div className="scrollbar-track scrollbar-track-x"></div>

              <div
                className={`scrollbar-track ${
                  showScrollbar ? "show" : ""
                } scrollbar-track-y`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
