import React, { useEffect, useState } from "react";
import {
  asyncClearSattlementRequests,
  asyncGetDailySattlements,
  asyncUpdateCommissionTopupRequestByAdmin,
} from "../../redux/slices/SattlementSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { asyncUpdateTopupStatus } from "../../redux/slices/walletSlice";
import TableV2 from "../../components/tableV2";
import moment from "moment";
import DeleteComfirmationModal from "../../components/modals/deleteConfirmationModal";
import CheckPermission from "../../utils/checkPermission";

function ClearSattlementTable() {
  const location = useLocation();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [query, setQuery] = useState("");

  const searchValueHandler = (value) => {
    setQuery(value);
  };

  const onApprove = () => {
    dispatch(
      asyncUpdateCommissionTopupRequestByAdmin({
        id: selectedItem,
        data: { status: "Approved" },
        callback: () => {
          setShowAcceptModal(false);
          toast.success("Request Approved Successfully!");
        },
      })
    );
  };

  // const showApproveModal = () => {setAcceptModalOpen(false)};
  const onReject = () => {
    dispatch(
      asyncUpdateCommissionTopupRequestByAdmin({
        id: selectedItem,
        data: { status: "Rejected" },
        callback: () => {
          setShowDeleteModal(false);
          toast.success("Request Rejected Successfully!");
        },
      })
    );
  };

  const columns = [
    {
      // key: "firstName",
      displayField: (e) => (
        <>
          {e?.transactionBy?.firstName} {e?.transactionBy?.lastName}
        </>
      ),
      displayName: "Passenger",
      searchable: true,
    },
    {
      // key: "lastName",
      displayField: (e) => (
        <>
          {" "}
          {e?.currency} {e?.amount}{" "}
        </>
      ),
      displayName: "Amount Request",
      searchable: true,
    },
    {
      key: "paymentMethod",
      displayName: "Payment Method",
      searchable: true,
    },
    {
      key: "phone",
      displayName: "Time",
      displayField: (e) => (
        <> {moment(e?.updatedAt).format("DD MMM, YYYY @ hh:mm a")} </>
      ),

      searchable: true,
    },
    {
      // key: "status",
      displayField: (e) => (
        <>
          {e.status === "Pending" ? (
            <span className="text-capitalize badge bg-primary">{e.status}</span>
          ) : e.status === "Approved" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
      displayName: "Status",
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <>
          {e.status === "Pending" ? (
            <div className="d-flex justify-content-start align-items-center">
              <button
                className="mr-2 text-danger bg-transparent border-none"
                disabled={e.status !== "Pending"}
              >
                <i
                  className="fas fa-check text-success"
                  onClick={() => {
                    setSelectedItem(e._id);
                    setShowAcceptModal(true);
                  }}
                />
              </button>

              <button
                className="mr-2 text-danger bg-transparent border-none "
                disabled={e.status !== "Pending"}
              >
                <i
                  className="fas fa-times"
                  onClick={() => {
                    setSelectedItem(e._id);
                    setShowDeleteModal(true);
                  }}
                />
              </button>
            </div>
          ) : (
            <div className="">N/A</div>
          )}
        </>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  const sattlements = useSelector((state) => state.sattlements);

  const { isLoading, requestList } = sattlements;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncClearSattlementRequests());
  }, [auth.country]);

  return (
    <div>
      {/* <Table dataSource={requestList} isLoading={isLoading} columns={columns} /> */}
      <TableV2
        dataSource={requestList || []}
        isLoading={isLoading}
        columns={columns}
        currentPage={page}                        
        setCurrentPage={setPage}
        itemsPerPage={limit}
        setItemsPerPage={setLimit}
        // totalEntries={wallets?.totalResults}
        // totalPages={wallets?.totalPages}
        searchValueHandler={searchValueHandler}
      />

      <DeleteComfirmationModal
        show={showAcceptModal}
        message={"Are you sure you want to approve this request?"}
        title={"Approve Request"}
        onHide={() => setShowAcceptModal(false)}
        onAccept={onApprove}
      />

      <DeleteComfirmationModal
        show={showDeleteModal}
        message={"Are you sure you want to reject this request?"}
        title={"Reject Request"}
        onHide={() => setShowDeleteModal(false)}
        onAccept={() => onReject()}
      />
    </div>
  );
}

export default ClearSattlementTable;
