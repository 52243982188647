import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { Link, useLocation } from "react-router-dom";
import {
  asyncDeleteVehicleCategories,
  asyncGetVehicleCategories,
} from "../../redux/slices/vehicleCategorySlice";
import CheckPermission from "../../utils/checkPermission";
import { BUCKET_PUBLIC_URL } from "../../constants";
import Swal from "sweetalert2";

const CabCategoryTable = () => {
  const vehicleCategories = useSelector((state) => state.vehicleCategories);
  const auth = useSelector((state) => state.auth);

  const { isLoading, list } = vehicleCategories;

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetVehicleCategories());
  }, [dispatch, auth.country]);

  const DeleteVehicleCategoryByIdHanlder = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });

        dispatch(
          asyncDeleteVehicleCategories({
            id: id,
          })
        );
      }
    });
  };

  const columns = [
    {
      key: "name",
      displayName: "Image",
      searchable: true,
      displayField: (e) => (
        <>
          <img
            src={`${BUCKET_PUBLIC_URL}/${e.imageUrl}`}
            alt="User Profile"
            height={40}
            width={40}
            className="object-fit-contain"
          />{" "}
        </>
      ),
    },
    {
      key: "name",
      displayName: "Name",
      searchable: true,
    },

    {
      displayName: "Engine CC",
      displayField: (e) => <>{e.engineCC} cc</>,
      searchable: true,
    },
    {
      displayName: "Seating Space",
      displayField: (e) => <>{e.seatingSpace} persons</>,
      searchable: true,
    },
    {
      key: "status",
      displayName: "Status",
      displayField: (e) => (
        <>
          {e.status === "Active" ? (
            <span className="text-capitalize badge bg-success">{e.status}</span>
          ) : (
            <span className="text-capitalize badge bg-danger">{e.status}</span>
          )}
        </>
      ),
    },
    {
      displayName: "Action",
      key: "",
      displayField: (e) => (
        <form
          method="POST"
          action="https://meetmighty.com/mobile/mighty-taxi/rider/36"
          acceptCharset="UTF-8"
          data--submit="rider36"
        >
          {/* {console.log(e, "eeeee")} */}
          <input name="_method" type="hidden" defaultValue="DELETE" />
          <input
            name="_token"
            type="hidden"
            defaultValue="nKXO2rpGnpIJanM4AITcm75gWgwnlaw7rTcv6PKI"
          />
          <div className="d-flex justify-content-start align-items-center">
            <Link className="mr-2" to={`${e?._id}`} title="Update Rider">
              <i className="fas fa-edit text-primary" />
            </Link>
            <Link className="mr-2" href="">
              <i className="fas fa-eye text-secondary" />
            </Link>

            <Link
              className="mr-2 "
              href=""
              style={{ color: "red" }}
              onClick={() => DeleteVehicleCategoryByIdHanlder(e?._id)}
            >
              <i className="fas fa-trash-alt" />
            </Link>
          </div>
        </form>
      ),
      searchable: true,
      hidden: CheckPermission(location),
    },
  ];

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
    </>
  );
};
export default CabCategoryTable;
