import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { uploadSingleFile } from "../../services/upload";
import { asyncUpdateUser } from "../../redux/slices/userSlice";
import { asyncGetCountries } from "../../redux/slices/countrySlice";
import { BUCKET_PUBLIC_URL } from "../../constants";
import { useUpdateUsersMutation } from "../../redux/services/userAPI";

function EditUser() {
  const countries = useSelector((state) => state.countries);
  const { isLoading } = countries;

  const users = useSelector((state) => state.users);
  const UserInfo = useSelector((state) => state.auth.userInfo);

  const { role, country } = UserInfo;
  const [formData, setFormData] = useState({
    profileImageUrl: "",
  });

  const { id } = useParams();

  useEffect(() => {
    const userFound = users?.results?.find((items) => items?._id === id);

    if (userFound) {
      setFormData({
        firstName: userFound?.firstName,
        lastName: userFound?.lastName,
        role: userFound?.role,
        password: userFound?.password,
        email: userFound?.email,
        profileImageUrl: userFound?.profileImageUrl,
        phone: userFound?.phone,
        country: userFound?.country,
      });
    }
  }, [id, users?.list]);

  const dispatch = useDispatch();

  const [UpdateUsers] = useUpdateUsersMutation();

  const profileInputRef = useRef();

  const [uploadingImage, setUploadingImage] = useState(false);

  const editProfilePictureHandler = async (e) => {
    const file = e.target.files[0];

    const directory = `profile-uploads/passengers/`;
    setUploadingImage(true);
    const profileImageUrl = await uploadSingleFile({
      file,
      directory,
      progressHandler: null,
      uploadCompleteHandler: () => setUploadingImage(false),
    });
    setFormData({ ...formData, profileImageUrl });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const postPassengerHandler = (event) => {
    event.preventDefault();
    UpdateUsers({
      payload: {
        ...formData,
        phone: formData.phone.includes("+")
          ? formData.phone
          : "+" + formData.phone,
      },
      id,
    });

    // dispatch(
    //   asyncUpdateUser({
    //     data: {
    //       ...formData,
    //       phone: formData.phone.includes("+")
    //         ? formData.phone
    //         : "+" + formData.phone,
    //     },
    //     callback: () => {
    //       // navigate("/users");
    //     },
    //     id: id,
    //   })
    // );
  };

  useEffect(() => {
    dispatch(asyncGetCountries());
  }, [dispatch]);

  return (
    <form onSubmit={postPassengerHandler}>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Add User</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="crm-profile-img-edit position-relative">
                  <img
                    src={`${BUCKET_PUBLIC_URL}/${formData?.profileImageUrl}`}
                    alt="User-Profile"
                    className="crm-profile-pic rounded-circle avatar-100"
                  />
                  <div
                    className="crm-p-image bg-primary"
                    onClick={() => profileInputRef.current.click()}
                  >
                    <svg
                      className="upload-button"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffffff"
                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                      />
                    </svg>
                    <input
                      className="file-upload"
                      type="file"
                      accept="image/*"
                      name="profile_image"
                      ref={profileInputRef}
                      onChange={editProfilePictureHandler}
                    />
                  </div>
                </div>{" "}
                {uploadingImage && (
                  <div className="mx-auto w-100 d-inline-block justify-content-center">
                    <span>Uploading image ...</span>
                  </div>
                )}
                <div className="img-extension mt-3">
                  <div className="d-inline-block align-items-center">
                    <span>Only</span>
                    <span>.png</span>
                    <span>.jpg</span>
                    <span>.jpeg</span>
                    <span>.gif</span>
                    <span>Allowed</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Status</label>
                <div className="grid">
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-active"
                      defaultChecked="checked"
                      name="status"
                      type="radio"
                      defaultValue="active"
                    />
                    <label htmlFor="status-active" className="form-check-label">
                      Active
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-inactive"
                      name="status"
                      type="radio"
                      defaultValue="inactive"
                    />
                    <label
                      htmlFor="status-inactive"
                      className="form-check-label"
                    >
                      Inactive
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-pending"
                      name="status"
                      type="radio"
                      defaultValue="pending"
                    />
                    <label
                      htmlFor="status-pending"
                      className="form-check-label"
                    >
                      Pending
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-banned"
                      name="status"
                      type="radio"
                      defaultValue="banned"
                    />
                    <label htmlFor="status-banned" className="form-check-label">
                      Banned
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Edit User Information</h4>
              </div>
              <div className="card-action">
                <Link
                  to="/passengers"
                  className="btn btn-sm btn-primary"
                  role="button"
                >
                  Back
                </Link>
              </div>
            </div>

            <div className="card-body">
              <div className="new-user-info">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="first_name" className="form-control-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      className="form-control"
                      required
                      name="firstName"
                      type="text"
                      id="first_name"
                      onChange={handleInputChange}
                      value={formData?.firstName}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="last_name" className="form-control-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Last Name"
                      className="form-control"
                      required
                      name="lastName"
                      type="text"
                      id="last_name"
                      onChange={handleInputChange}
                      value={formData?.lastName}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-control-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Email"
                      className="form-control"
                      required=""
                      name="email"
                      type="email"
                      id="email"
                      onChange={handleInputChange}
                      value={formData?.email}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-control-label">
                      Passowrd <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="password"
                      className="form-control"
                      required=""
                      name="password"
                      type="password"
                      id="password"
                      onChange={handleInputChange}
                      value={formData?.password}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label for="role" className="form-control-label">
                      Role <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      required
                      id="role"
                      name="role"
                      data-select2-id="Role"
                      tabindex="-1"
                      aria-hidden="true"
                      onChange={handleInputChange}
                      value={formData?.role}
                    >
                      <option value=""> Please choose role</option>
                      <option value="country_admin">Country Admin</option>
                      <option value="super_admin">Super Admin</option>
                    </select>
                  </div>

                  {formData?.role === "country_admin" && (
                    <div className="form-group col-md-6">
                      <label for="role" className="form-control-label">
                        Country <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        required
                        id="country"
                        name="country"
                        onChange={handleInputChange}
                        value={formData?.country}
                      >
                        {countries?.list?.map((item) => (
                          <option value={item._id}> {item.name} </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      Phone <span className="text-danger">*</span>
                    </label>

                    {/* <PhoneInput
                      placeholder="Enter phone number"
                      value={formData?.phone}
                      onChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "phone",
                            value: value,
                          },
                        })
                      }
                      country={"us"}
                      onlyCountries={["so", "ke", "ug", "us", "pk"]}
                      prefix="+"
                      addInternationalOption={false}
                      className=" phone-input"
                      required={true}
                    /> */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formData.phone}
                      onChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "phone",
                            value: value,
                          },
                        })
                      }
                      country={
                        country?.shortName
                          ? country.shortName.toLowerCase()
                          : "pk"
                      }
                      onlyCountries={
                        role === "super_admin"
                          ? ["so", "ke", "ug", "us", "pk"]
                          : [country.shortName.toLowerCase()]
                      }
                      prefix="+"
                      addInternationalOption={false}
                      className=" phone-input"
                      required={true}
                    />
                  </div>
                </div>
                <hr />

                <CustomButton
                  className="btn btn-md mb-4 btn-primary float-right"
                  title="Submit"
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditUser;
