import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PhoneInput from "react-phone-input-2";
import { uploadSingleFile } from "../../services/upload";
import CustomButton from "../../components/CustomButton";
import { asyncUpdateProfile } from "../../redux/slices/authSlice";
import { BUCKET_PUBLIC_URL } from "../../constants";

function ProfileDetails() {
  const profileInputRef = useRef();
  //   const { id } = useParams();

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState({});
  const [uploadingImage, setUploadingImage] = useState(false);

  const editProfilePictureHandler = async (e) => {
    const file = e.target.files[0];

    const directory = `profile-uploads/${auth.userInfo._id}/`;
    setUploadingImage(true);
    const profileImageUrl = await uploadSingleFile({
      file,
      directory,
      progressHandler: null,
      uploadCompleteHandler: () => setUploadingImage(false),
    });
    setProfileData({ ...profileData, profileImageUrl });
  };

  useEffect(() => {
    setProfileData({
      firstName: auth.userInfo.firstName,
      lastName: auth.userInfo.lastName,
      email: auth.userInfo.email,
      phone: auth.userInfo.phone,
      address: auth.userInfo.address,
      profileImageUrl: auth.userInfo.profileImageUrl,
    });
  }, [auth.userInfo]);

  const adminValueHandler = (event) => {
    const { name, value } = event.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const postPassengerHandler = (event) => {
    dispatch(
      asyncUpdateProfile({
        obj: {
          ...profileData,
          phone: profileData.phone.includes("+")
            ? profileData.phone
            : "+" + profileData.phone,
        },
        // callback: () => {
        //   navigate("/passengers"); // Navigate to the desired route
        // },
      })
    );
  };
  return (
    <div className="tab-content">
      <div className="paste_here active">
        <div className="col-md-12">
          <div className="row ">
            <div className="col-md-3">
              <div className="user-sidebar">
                <div className="user-body user-profile text-center">
                  {/* <div className="user-img">
                  <img
                    className="rounded-circle avatar-100 image-fluid profile_image_preview"
                    src="https://meetmighty.com/mobile/mighty-taxi/images/user/1.jpg"
                    alt="profile-pic"
                  />
                </div> */}{" "}
                  <div className="crm-profile-img-edit position-relative">
                    <img
                      src={`${BUCKET_PUBLIC_URL}/${profileData.profileImageUrl}`}
                      alt="User-Profile"
                      className="crm-profile-pic rounded-circle avatar-100"
                    />
                    <div
                      className="crm-p-image bg-primary"
                      onClick={() => profileInputRef.current.click()}
                    >
                      <svg
                        className="upload-button"
                        width={14}
                        height={14}
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                        />
                      </svg>
                      <input
                        ref={profileInputRef}
                        className="file-upload"
                        type="file"
                        accept="image/*"
                        name="profile_image"
                        onChange={editProfilePictureHandler}
                      />
                    </div>
                  </div>
                  {uploadingImage && (
                    <div className="sideuser-info">
                      <span className="mb-2">Uploading Image ...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="user-content">
                <input type="hidden" name="profile" value="profile" />
                <input name="username" type="hidden" value="admin" />
                <input name="email" type="hidden" value="admin@admin.com" />
                <input
                  placeholder="id"
                  className="form-control"
                  name="id"
                  type="hidden"
                  value="1"
                />
                <div className="row ">
                  <div className="form-group col-md-6">
                    <label for="first_name" className="form-control-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      className="form-control"
                      required=""
                      name="firstName"
                      type="text"
                      defaultValue={profileData.firstName}
                      onChange={adminValueHandler}
                      id="first_name"
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label for="last_name" className="form-control-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Last Name"
                      className="form-control"
                      required=""
                      name="lastName"
                      type="text"
                      id="last_name"
                      defaultValue={profileData.lastName}
                      onChange={adminValueHandler}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label for="email" className="form-control-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Email"
                      className="form-control"
                      required=""
                      name="email"
                      type="email"
                      id="email"
                      value={profileData.email}
                      onChange={adminValueHandler}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label for="contact_number" className="form-control-label">
                      Contact Number <span className="text-danger">*</span>
                    </label>

                    <PhoneInput
                      placeholder="Enter phone number"
                      value={profileData.phone}
                      onChange={(value) =>
                        adminValueHandler({
                          target: {
                            name: "phone",
                            value: value,
                          },
                        })
                      }
                      country={"us"}
                      // defaultCountry={country?.short_name}
                      onlyCountries={["so", "ke", "ug", "us", "pk"]}
                      prefix="+"
                      addInternationalOption={false}
                      className=" phone-input"
                      // className="w-100"
                      required={true}
                    />
                  </div>

                  <div className="form-group col-md-12">
                    <label for="address" className="form-control-label">
                      Address
                    </label>
                    <textarea
                      className="form-control textarea"
                      rows="3"
                      placeholder="Address"
                      name="address"
                      cols="50"
                      id="address"
                      value={profileData.address}
                      onChange={adminValueHandler}
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <CustomButton
                      title="Update"
                      isLoading={auth.isLoading}
                      className="btn btn-md btn-primary float-md-right"
                      type="submit"
                      value="Update"
                      onClick={postPassengerHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
