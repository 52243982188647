import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncUpdateDriver } from "../../redux/slices/driverSlice";
import CustomButton from "../../components/CustomButton";
import { uploadSingleFile } from "../../services/upload";
import { BUCKET_PUBLIC_URL } from "../../constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function EditDriver() {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const driver = useSelector((state) => state.drivers);

  const { list, isLoading } = driver;

  const profileInputRef = useRef();
  const [uploadingImage, setUploadingImage] = useState(false);
  const editProfilePictureHandler = async (e) => {
    const file = e.target.files[0];

    const directory = `profile-uploads/drivers/`;
    setUploadingImage(true);
    const profileImageUrl = await uploadSingleFile({
      file,
      directory,
      progressHandler: null,
      uploadCompleteHandler: () => setUploadingImage(false),
    });
    setFormData({ ...formData, profileImageUrl });
  };

  useEffect(() => {
    const driverFound = list.find((x) => x._id === id);

    if (driverFound) {
      setFormData({
        firstName: driverFound.firstName,
        lastName: driverFound.lastName,
        phone: driverFound.phone,
        identityCardNumber: driverFound.identityCardNumber,
        identityCardExpiry: driverFound.identityCardExpiry,
        email: driverFound.email,
        profileImageUrl: driverFound.profileImageUrl,
        drivingLicenseNumber: driverFound.drivingLicenseNumber,
        drivingLicenseExpiry: driverFound.drivingLicenseExpiry,
        status: driverFound.status,
        verification: driverFound.verification,
      });
    } else {
      navigate(-1);
    }
  }, [navigate, list, id]);

  const editDriverHandler = (event) => {
    const { phone, ...restFormData } = formData;
    dispatch(
      asyncUpdateDriver({
        obj: {
          ...restFormData,
          // phone: formData.phone.includes("+")
          //   ? formData.phone
          //   : "+" + formData.phone,
        },
        id: id,
        callback: () => {
          navigate("/drivers");
        },
      })
    );
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "radio") {
      // Handle radio button changes
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      // Handle other input changes
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Update Driver</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="crm-profile-img-edit position-relative">
                  <img
                    src={`${BUCKET_PUBLIC_URL}/${formData.profileImageUrl}`}
                    alt="User-Profile"
                    className="crm-profile-pic rounded-circle avatar-100"
                  />
                  <div
                    className="crm-p-image bg-primary"
                    onClick={() => profileInputRef.current.click()}
                  >
                    <svg
                      className="upload-button"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffffff"
                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                      />
                    </svg>
                    <input
                      className="file-upload"
                      type="file"
                      accept="image/*"
                      name="profile_image"
                      ref={profileInputRef}
                      onChange={editProfilePictureHandler}
                    />
                  </div>
                </div>
                {uploadingImage && (
                  <div className="mx-auto w-100 d-inline-block justify-content-center">
                    <span>Uploading image ...</span>
                  </div>
                )}
                <div className="img-extension mt-3">
                  <div className="d-inline-block align-items-center">
                    <span>Only</span>
                    <span>.png</span>
                    <span>.jpg</span>
                    <span>.jpeg</span>
                    <span>.gif</span>
                    <span>Allowed</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  <strong>Status:</strong>
                </label>
                <div className="grid">
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-active"
                      name="status"
                      type="radio"
                      value="Active" // Set the value for the "Active" option
                      checked={formData.status === "Active"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label htmlFor="status-active" className="form-check-label">
                      Active
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-inactive"
                      name="status"
                      type="radio"
                      value="Inactive" // Set the value for the "Active" option
                      checked={formData.status === "Inactive"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label
                      htmlFor="status-inactive"
                      className="form-check-label"
                    >
                      Inactive
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-pending"
                      name="status"
                      type="radio"
                      value="Pending" // Set the value for the "Active" option
                      checked={formData.status === "Pending"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label
                      htmlFor="status-pending"
                      className="form-check-label"
                    >
                      Pending
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-banned"
                      name="status"
                      type="radio"
                      value="Banned" // Set the value for the "Active" option
                      checked={formData.status === "Banned"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label htmlFor="status-banned" className="form-check-label">
                      Banned
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label ">
                  <strong>Verification:</strong>
                </label>
                <div className="grid">
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="verification-pending"
                      name="verification"
                      type="radio"
                      value="Pending" // Set the value for the "Active" option
                      checked={formData.verification === "Pending"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label
                      htmlFor="verification-pending"
                      className="form-check-label"
                    >
                      Pending
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="verification-verified"
                      name="verification"
                      type="radio"
                      value="Verified" // Set the value for the "Active" option
                      checked={formData.verification === "Verified"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label
                      htmlFor="verification-verified"
                      className="form-check-label"
                    >
                      Verified
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="verification-rejected"
                      name="verification"
                      type="radio"
                      value="Rejected" // Set the value for the "Active" option
                      checked={formData.verification === "Rejected"} // Check if this radio button is selected
                      onChange={handleInputChange} // Call the handler on change
                    />
                    <label
                      htmlFor="verification-rejected"
                      className="form-check-label"
                    >
                      Rejected
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Update Driver Information</h4>
              </div>
              <div className="card-action">
                <Link
                  to="/drivers"
                  className="btn btn-sm btn-primary"
                  role="button"
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="new-user-info">
                <div style={{ margin: "26px 0" }} className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="first_name" className="form-control-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      className="form-control"
                      required=""
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      id="first_name"
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="last_name" className="form-control-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Last Name"
                      className="form-control"
                      required=""
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      id="last_name"
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-control-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Email"
                      className="form-control"
                      required=""
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      id="email"
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      Phone <span className="text-danger">*</span>
                    </label>
                    {/* <input                                                                                                                         
                      placeholder="Phone"
                      className="form-control"
                      required=""
                      name="phone"
                      type="tel"
                      id="contact_number"    
                      value={formData.phone}
                      onChange={handleInputChange}
                    /> */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formData.phone}
                      onChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "phone",
                            value: value,
                          },
                        })
                      }
                      // country={"PK"}
                      country={"us"}
                      // defaultCountry={country?.short_name}
                      onlyCountries={["so", "ke", "ug", "us", "pk"]}
                      prefix="+"
                      addInternationalOption={false}
                      className=" phone-input"
                      // className="w-100"
                      required={true}
                    />
                  </div>

                  {/* <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      ID Card Number <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="ID Card Number"
                      className="form-control"
                      required=""
                      name="identityCardNumber"
                      type="number"
                      id="contact_number"
                      value={parseInt(formData.identityCardNumber)}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      ID Card Expiry <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="drivingLicenseNumber"
                      className="form-control"
                      required=""
                      name="identityCardExpiry"
                      type="date"
                      id="contact_number"
                      value={formData.identityCardExpiry}
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      Driving License Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Driving License Number"
                      className="form-control"
                      required=""
                      name="drivingLicenseNumber"
                      type="text"
                      id="contact_number"
                      value={formData.drivingLicenseNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      Driving License Expiry{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required=""
                      name="drivingLicenseExpiry"
                      type="date"
                      id="contact_number"
                      value={formData.drivingLicenseExpiry}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <hr />
                <CustomButton
                  className="btn btn-md btn-primary float-right"
                  title="Update"
                  isLoading={isLoading}
                  onClick={editDriverHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDriver;
