import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/table";
import { asyncGetDrivers } from "../../redux/slices/driverSlice";
import { Link, useLocation } from "react-router-dom";
import { asyncGetPassengers } from "../../redux/slices/passengerSlice";
import DefaultProfileImg from "../../assets/images/default-profile-img.png";
import sidebarList from "../../layout/AdminLayout/MenuSidebar";
import CheckPermission from "../../utils/checkPermission";
import { useGetPaymentMethodQuery } from "../../redux/services/paymentAPI";
import { BUCKET_PUBLIC_URL } from "../../constants";

const PaymentTable = () => {


  

  const { data, isLoading, error } = useGetPaymentMethodQuery({});

 

  const columns = [


    {
      key: "Name",
      displayName: "Name",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <img
            width={40}
            height={40}
            src={`${BUCKET_PUBLIC_URL}/${e?.imageUrl}`}
            className="rounded-circle object-fit-cover"
           
          />
          <p className="ml-2 mb-0">
            {e?.name} 
          </p>
        </div>
      ),
      searchable: true,
    },
   
    {
      key: "description",
      displayName: "Description",
      displayField: (e) => <div className="d-flex align-items-center">
        {e?.description}
      </div>,
      searchable: true,
    },
  ];

  return (
    <>
      <Table dataSource={data || []} isLoading={isLoading} columns={columns} />
    </>
  );
};

export default PaymentTable;
