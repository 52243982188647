import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { asyncUpdatePassenger } from "../../redux/slices/passengerSlice";
import { uploadSingleFile } from "../../services/upload";
import { BUCKET_PUBLIC_URL } from "../../constants";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function EditPassenger() {
  let dispatch = useDispatch();
  const passengers = useSelector((state) => state.passengers);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const { list } = passengers;
  const { id } = useParams();
  const profileInputRef = useRef();

  const [uploadingImage, setUploadingImage] = useState(false);
  const [disbale, setDisbale] = useState(false);

  const editProfilePictureHandler = async (e) => {
    const file = e.target.files[0];

    const directory = `profile-uploads/passengers/`;
    setUploadingImage(true);
    const profileImageUrl = await uploadSingleFile({
      file,
      directory,
      progressHandler: null,
      uploadCompleteHandler: () => setUploadingImage(false),
    });
    setFormData({ ...formData, profileImageUrl });
  };
  useEffect(() => {
    let passengerFound = list.find((x) => x._id === id);
    if (passengerFound) {
      setFormData({
        profileImageUrl: passengerFound.profileImageUrl,
        firstName: passengerFound.firstName,
        lastName: passengerFound.lastName,
        phone: passengerFound.phone,
        email: passengerFound.email,
        dateOfBirth: passengerFound.dateOfBirth,
        address: passengerFound.address,
        gender: passengerFound.gender,
      });
      setDisbale(true);
    } else {
      navigate("/passengers");
    }
  }, [navigate, list, id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { phone, email, ...safeUserData } = formData;

  const editPassangerHandler = () => {
    dispatch(
      asyncUpdatePassenger({
        obj: {
          ...safeUserData,
        },
        id: id,
        callback: () => {
          navigate("/passengers");
        },
      })
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Update Passenger</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="crm-profile-img-edit position-relative">
                  <img
                    src={`${BUCKET_PUBLIC_URL}/${formData.profileImageUrl}`}
                    alt="User-Profile"
                    className="crm-profile-pic rounded-circle avatar-100"
                  />
                  <div
                    className="crm-p-image bg-primary"
                    onClick={() => profileInputRef.current.click()}
                  >
                    <svg
                      className="upload-button"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffffff"
                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                      />
                    </svg>
                    <input
                      className="file-upload"
                      type="file"
                      accept="image/*"
                      name="profile_image"
                      ref={profileInputRef}
                      onChange={editProfilePictureHandler}
                    />
                  </div>
                </div>{" "}
                {uploadingImage && (
                  <div className="mx-auto w-100 d-inline-block justify-content-center">
                    <span>Uploading image ...</span>
                  </div>
                )}
                <div className="img-extension mt-3">
                  <div className="d-inline-block align-items-center">
                    <span>Only</span>
                    <span>.png</span>
                    <span>.jpg</span>
                    <span>.jpeg</span>
                    <span>.gif</span>
                    <span>Allowed</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Status</label>
                <div className="grid">
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-active"
                      defaultChecked="checked"
                      name="status"
                      type="radio"
                      defaultValue="active"
                    />
                    <label htmlFor="status-active" className="form-check-label">
                      Active
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-inactive"
                      name="status"
                      type="radio"
                      defaultValue="inactive"
                    />
                    <label
                      htmlFor="status-inactive"
                      className="form-check-label"
                    >
                      Inactive
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-pending"
                      name="status"
                      type="radio"
                      defaultValue="pending"
                    />
                    <label
                      htmlFor="status-pending"
                      className="form-check-label"
                    >
                      Pending
                    </label>
                  </div>
                  <div className="form-check g-col-6">
                    <input
                      className="form-check-input"
                      id="status-banned"
                      name="status"
                      type="radio"
                      defaultValue="banned"
                    />
                    <label htmlFor="status-banned" className="form-check-label">
                      Banned
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Update Passenger Information</h4>
              </div>
              <div className="card-action">
                <Link
                  to="/passengers"
                  className="btn btn-sm btn-primary"
                  role="button"
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="new-user-info">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="first_name" className="form-control-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      className="form-control"
                      required=""
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      id="first_name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="last_name" className="form-control-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Last Name"
                      className="form-control"
                      required=""
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      id="last_name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-control-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Email"
                      className="form-control"
                      required=""
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      id="email"
                      disabled={formData.email}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label for="gender" className="form-control-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control "
                      required=""
                      id="gender"
                      name="gender"
                      data-select2-id="gender"
                      tabindex="-1"
                      aria-hidden="true"
                      onChange={handleInputChange}
                      value={formData.gender}
                    >
                      <option value="Male">Male</option>
                      <option
                        value="Female"
                        selected="selected"
                        data-select2-id="2"
                      >
                        Female
                      </option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="username" className="form-control-label">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      required=""
                      name="dateOfBirth"
                      type="date"
                      value={formData.dateOfBirth}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      htmlFor="contact_number"
                      className="form-control-label"
                    >
                      Phone <span className="text-danger">*</span>
                    </label>
                    {/* <input
                      placeholder="Phone"
                      className="form-control"
                      required=""
                      name="phone"
                      type="number"
                      id="contact_number"
                      defaultValue={formData.phone}
                    /> */}

                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formData.phone}
                      disabled={disbale}
                      onChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "phone",
                            value: value,
                          },
                        })
                      }
                      // country={"PK"}
                      country={"us"}
                      // defaultCountry={country?.short_name}
                      onlyCountries={["so", "ke", "ug", "us", "pk"]}
                      prefix="+"
                      addInternationalOption={false}
                      className=" phone-input"
                      // className="w-100"
                      required={true}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="address" className="form-control-label">
                      Address
                    </label>
                    <textarea
                      className="form-control textarea"
                      rows={3}
                      placeholder="Address"
                      name="address"
                      cols={50}
                      id="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <hr />
                <button
                  onClick={editPassangerHandler}
                  className="btn btn-md btn-primary float-right"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPassenger;
